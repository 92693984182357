



















































// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';

// --- Models ---

// --- Services ---
import AppService from '@/services/app';
import AuthService from '@/services/auth';

// --- Third Party imports ---

@Component
export default class VerifyPage extends Vue {

  async beforeMount() {
    // get our token
    const token: string = this.$route.params.token;

    if (token && token.length > 0) {

      // get our username
      const usernameParam: any = this.$route.query.username;

      if (typeof usernameParam === 'string') {
        const username: string = usernameParam as string;

        if (username && username.length > 0) {
          const response = await AuthService.verify({ 
            token: token, 
            username: username,
          });

          if (response && response.data) {
            if ((response.data.result && response.data.result === 'false')) {
              AppService.errorHandlerWithCustomTimeout(response.data.message, 6000);
            } else {
              // good to go
              AppService.successHandlerWithCustomTimeout(response.data.message, 6000);
            }

            this.$router.push({ name: 'Login'});
          }

        } else {
          this.failVerification();
        }
      } else {
        this.failVerification();
      }
    } else {
      // this should be handled by the router, but just in case
      this.failVerification();
    }
  }

  getCopyRight() {
    return process.env.VUE_APP_COPYRIGHT;
  }

  failVerification() {
    AppService.errorHandlerWithCustomTimeout('Could not complete your eazigear Registration. Please contact Customer Support.', 6000);
    this.$router.push({ name: 'Login'});
  }

}
